// @ts-nocheck FIXME
import PropTypes from 'prop-types'
import { NavLink, useLocation } from 'react-router-dom'
import { Box, Inline, WrapperButton, Text, Icon, Badge, Divider } from '@leadrilla/pulsar'
import useImage from '../../hooks/image'
import styled from '@emotion/styled'
import poweredByLogo from '../../assets/svg/powered-by-salesriver-logo.svg'
import { useTenant, isLeadrilla } from '../../hooks/TenantConfig'
import { isAdminForAtLeastOneTeam } from '../../helpers/teams'
import { PoweredByLogo } from '../PoweredByLogo/PoweredByLogo'
import cn from 'classnames'

function NavItem({ external, to, iconName, text, close, className }) {
  if (external) {
    return (
      <StyledAnchor href={to} target="_blank" onClick={close} rel="noreferrer">
        <div className="flex items-center space-x-4">
          <StyledIcon name={iconName} />
          <StyledText size="bigger">{text}</StyledText>
        </div>
      </StyledAnchor>
    )
  }
  return (
    <StyledNavLink to={to} onClick={close} className={className}>
      <div className="flex items-center space-x-4">
        <StyledIcon name={iconName} />
        <StyledText size="big">{text}</StyledText>
      </div>
    </StyledNavLink>
  )
}

export default function MobileMenu({ close, impersonating, cancelImpersonation, user }) {
  const tenant = useTenant()
  const { logOut } = useContext(UserContext)
  const tenantConfig = tenant.config
  const { image: logo } = useImage(`${tenant.name}-logo.svg`)
  const { pathname } = useLocation()

  return (
    <aside className="flex h-full flex-col justify-between">
      <div className="space-y-12">
        <div className="mt-8 flex items-center justify-between">
          <div width="116">
            <StyledMobileLogo src={logo} alt="Brand Logo" />
          </div>
          <WrapperButton onClick={close}>
            <Icon name="close" tone="subtle" size="l" />
          </WrapperButton>
        </div>

        <div className="space-y-12 pt-8">
          <NavItem
            className={cn({ selected: pathname === '/dash/my-leads' })}
            to="/dash/my-leads"
            close={close}
            text="My Leads"
            iconName={pathname === '/dash/my-leads' ? 'sell-tt' : 'sell'}
          />
          <div className="space-y-12">
            {tenantConfig.campaigns.enabled && (
              <NavItem
                className={cn({ selected: pathname === '/dash/campaigns' })}
                to="/dash/campaigns"
                close={close}
                text="Campaigns"
                iconName={pathname === '/dash/campaigns' ? 'campaign-tt' : 'campaign'}
              />
            )}

            {tenantConfig.voip.voice?.enabled && (
              <NavItem to="/dash/call-log" close={close} text="Call Log" iconName="phone-tt" />
            )}
            {tenantConfig.teams.enabled && (
              <div>
                {user.type === 'admin' ||
                (user.teams.length > 0 && isAdminForAtLeastOneTeam({ teams: user.teams })) ? (
                  // Platform admins can see this page regardless
                  <NavItem
                    to="/dash/teams"
                    close={close}
                    text="Teams"
                    iconName={pathname.startsWith('/dash/teams') ? 'teams-tt' : 'teams'}
                    className={cn({ selected: pathname.startsWith('/dash/teams') })}
                  />
                ) : null}
              </div>
            )}
          </div>
          <div>
            {!tenantConfig.disableMarketplace && (
              <NavItem
                className={cn({ selected: pathname === '/dash/marketplace' })}
                to="/dash/marketplace"
                close={close}
                text="Marketplace"
                iconName={pathname === '/dash/marketplace' ? 'marketplace-tt' : 'marketplace'}
              />
            )}
          </div>
          <div>
            <NavItem
              className={cn({ selected: pathname === '/dash/intergrations' })}
              to="/dash/integrations"
              close={close}
              text="Integrations"
              iconName={pathname === '/dash/integrations' ? 'integration-tt' : 'integration'}
            />
          </div>

          {!tenantConfig.navigation?.hide_faq && (
            <div>
              <NavItem
                className={cn({ selected: pathname === '/dash/faq' })}
                to="/dash/faq"
                close={close}
                text="FAQs"
                iconName={pathname === '/dash/faq' ? 'learn-tt' : 'learn'}
              />{' '}
            </div>
          )}

          <div>
            {tenantConfig.voip.voice?.enabled && (
              <NavItem to="/dash/call-log" close={close} text="Call Log" iconName="phone-tt" />
            )}
          </div>

          {tenantConfig.custom_menu_links?.map(({ text, icon, href }) => (
            <NavItem to={href} close={close} text={text} iconName={icon} external />
          ))}
          {impersonating && (
            <Box as="button" type="button" onClick={cancelImpersonation}>
              <Inline space="l" alignY="center">
                <Icon name="close" tone="negative" />
                <Text tone="negative" size="bigger">
                  Stop Impersonation
                </Text>
              </Inline>
            </Box>
          )}
          <Divider />
          {user.type === 'admin' && (
            <div className="space-y-12 pt-12">
              <Text weight="strong" size="big" tone="light">
                Admin
              </Text>
              <div>
                <NavItem
                  className={cn({ selected: pathname === '/dash/reports' })}
                  to="/dash/reports"
                  close={close}
                  text="Reports"
                  iconName={pathname === '/dash/reports' ? 'metrics-tt' : 'metrics'}
                />
              </div>
              <div>
                <NavItem
                  className={cn({ selected: pathname === '/dash/users' })}
                  to="/dash/users"
                  close={close}
                  text="Users"
                  iconName={pathname === '/dash/users' ? 'users-tt' : 'users'}
                />
              </div>
              {tenantConfig.product_settings?.enabled && (
                <div>
                  <NavItem
                    className={cn({ selected: pathname === '/dash/products' })}
                    to="/dash/products"
                    close={close}
                    text="Product Settings"
                    iconName={pathname === '/dash/products' ? 'settings-tt' : 'settings'}
                  />
                </div>
              )}
              {tenantConfig.webhook_settings?.enabled && (
                <div>
                  <NavItem
                    className={cn({ selected: pathname === '/dash/webhooks' })}
                    to="/dash/webhooks"
                    close={close}
                    text="Webhook Settings"
                    iconName={pathname === '/dash/webhooks' ? 'settings-tt' : 'settings'}
                  />
                </div>
              )}
              <div>
                <NavItem
                  className={cn({ selected: pathname === '/dash/promotions' })}
                  to="/dash/promotions"
                  close={close}
                  text="Promotions"
                  iconName={pathname === '/dash/promotions' ? 'celebrate-tt' : 'celebrate'}
                />
              </div>
            </div>
          )}
          <Divider />
          <div className="pt-12">
            <NavItem
              className={cn({ selected: pathname === '/dash/billing' })}
              to="/dash/billing"
              close={close}
              text="Billing"
              iconName={pathname === '/dash/billing' ? 'list-tt' : 'list'}
            />
          </div>
          <div>
            <NavItem
              className={cn({ selected: pathname === '/dash/settings' })}
              to="/dash/settings"
              close={close}
              text="Settings"
              iconName={pathname === '/dash/settings' ? 'settings-tt' : 'settings'}
            />
          </div>
          <LogoutNavItem onClick={logOut}>
            <div className="flex items-center space-x-4">
              <StyledIcon name="logout" />
              <StyledText size="bigger">Logout</StyledText>
            </div>
          </LogoutNavItem>
          {(!isLeadrilla() || impersonating) && (
            <>
              <Divider />
              <div className="space-y-12 pt-12">
                <StyledAnchor
                  href={`mailto:${tenantConfig.emails?.support_email}?subject=${
                    tenantConfig.emails?.support_email_subject ||
                    tenantConfig.display_name + ' Question'
                  }`}
                  title={tenantConfig.emails?.support_email}
                >
                  <div className="flex items-center space-x-4">
                    <StyledIcon name="support" />
                    <StyledText size="big">Need Support?</StyledText>
                  </div>
                </StyledAnchor>
                {tenantConfig.referral_program.enabled && (
                  <div>
                    <NavLink to="/dash/refer" onClick={close}>
                      <Badge tone="action">Refer friends, earn $50!</Badge>
                    </NavLink>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>

      <PoweredByLogo
        className="mt-12"
        src={poweredByLogo}
        alt="Powered by Salesriver"
        height={32}
        width={116}
      />
    </aside>
  )
}

MobileMenu.propTypes = {
  user: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
}

const StyledMobileLogo = styled('img')`
  max-height: 34px;
`

const LogoutNavItem = styled('div')`
  color: #515459;

  :hover {
    cursor: pointer;
    color: var(--button-hover--primary);
  }

  &:active {
    color: var(--choice-chip-text--active);
    font-weight: 700;
  }

  &.selected {
    color: var(--colors-icon-base);
    font-weight: 600;
  }
`

const StyledText = styled(Text)`
  transition: color 0.2s;
  color: inherit;
  font-weight: inherit;
`

const StyledIcon = styled(Icon)`
  transition: color 0.2s;
  color: inherit !important;
`

const StyledNavLink = styled(NavLink)`
  color: #515459;

  &:hover {
    color: var(--button-hover--primary) !important;
  }

  &:active {
    color: var(--choice-chip-text--active);
    font-weight: 700;
  }

  &.selected {
    color: var(--colors-icon-base);
    font-weight: 600;
  }
`

const StyledAnchor = styled.a`
  color: #515459;

  :hover {
    cursor: pointer;
    color: var(--button-hover--primary);
  }

  &:active {
    color: var(--choice-chip-text--active);
    font-weight: 700;
  }

  &.selected {
    color: var(--colors-icon-base);
    font-weight: 600;
  }
`
